<script setup>
const config = useRuntimeConfig()
const imageUrl = config.public.IMAGE_RESIZE_URL
</script>

<script>
import { mapGetters } from 'vuex'
import OnboardingPopover from '~/components/onboarding/OnboardingPopover'
import GooglePlayBadge from '~/assets/icons/google-play-badge.svg?url'
import AppStoreBadge from '~/assets/icons/app-store-badge.svg'
import InfinityIcon from '~/assets/icons/infinity.svg'
import LazyImage from '~/components/LazyImage'
import { ONBOARDING_VARIANTS } from '~/utils/constants'

export default {
  components: {
    AppStoreBadge,
    OnboardingPopover,
    InfinityIcon,
    LazyImage,
  },
  props: {
    showOnboarding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      onboardingStep: 'onboarding/step',
      onboardingVariant: 'onboarding/variant',
      onboardingLabel: 'onboarding/label',
    }),
    onboardingVisible() {
      return (
        this.showOnboarding &&
        this.onboardingStep === 2 &&
        this.onboardingVariant === ONBOARDING_VARIANTS.premium
      )
    },
  },
}
</script>

<template>
  <OnboardingPopover
    position="top"
    :is-visible="onboardingVisible"
    :title="$label(`${onboardingLabel}.title`)"
    :text="$label(`${onboardingLabel}.content`)"
  >
    <div
      :class="[
        'app-banner__wrapper position-relative overflow-hidden',
        { 'app-banner__wrapper--highlighted': onboardingVisible },
      ]"
    >
      <div class="app-banner container pt-72">
        <div class="row">
          <div
            class="col-12 col-md-7 d-flex justify-content-center justify-content-md-end"
          >
            <div>
              <h3>
                {{ $label('page.myBooks.appPromoBanner.title') }}
              </h3>
              <span>
                {{ $label('page.myBooks.appPromoBanner.subtitle') }}
              </span>

              <div
                class="app-banner__badges d-flex align-items-center flex-wrap mt-24"
              >
                <a
                  id="googlePlay"
                  :href="$label('page.myBooks.appPromoBanner.googlePlayLink')"
                  target="_blank"
                  class="mr-8 mr-md-16"
                >
                  <!-- workaround for vite-svg-loader SVG issue -->
                  <svg width="160" height="48">
                    <image :xlink:href="GooglePlayBadge" />
                  </svg>
                </a>

                <a
                  :href="$label('page.myBooks.appPromoBanner.appleStoreLink')"
                  target="_blank"
                >
                  <AppStoreBadge />
                </a>
              </div>
            </div>
          </div>

          <div
            class="col-12 col-md-5 d-flex flex-column justify-content-end pl-56"
          >
            <LazyImage
              :src="`${imageUrl}?image=fe-assets/app-devices.png&width=464&height=446&fit=contain`"
              class="app-device__img"
              :width="230"
              :height="223"
              fluid
              placeholder-color="transparent"
            />
          </div>
        </div>
      </div>

      <InfinityIcon width="649px" height="396px" class="infinity-icon" />
    </div>
  </OnboardingPopover>
</template>

<style lang="scss" scoped>
.app-banner__wrapper {
  background: $grey-50;
  z-index: 0;

  &--highlighted {
    z-index: $popover-highlighted-el-zindex;
  }
}

.infinity-icon {
  fill: $blue-700;
  position: absolute;
  left: calc(100% / 2 + 60px);
  bottom: -45%;
  opacity: 0.2;
  z-index: -1;
}

.app-banner__badges {
  margin-bottom: 128px;

  svg {
    width: 160px;
  }
}

@include media-breakpoint-down(md) {
  .app-banner__wrapper {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .app-banner__badges {
    justify-content: center;
    margin-bottom: 60px;
  }

  .infinity-icon {
    display: none;
  }
}
</style>
